import React, { useRef, useLayoutEffect } from "react";
import HorizontalText from "../../horizontal-moving-text/HorizontalText";
import "./webDevelopmentStyle.scss";
import styled from "styled-components";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import img1 from "../../../images/empireThumbnail.png";
import img2 from "../../../images/sushibathumbnail.png";
import img3 from "../../../images/vibethumbnail.png";
import coco from "../../../images/coco.png";
import vv from "../../../images/covervv.png";
import ftPWC from "../../../images/ft-pwc.png";
import detex from "../../../images/detex-thumbnail.png";
import placeholder from "../../../images/placeholder.png";

import { motion } from "framer-motion";
import HorizontalLeft from "../../horizontal-moving-text/HorizontalLeft";
import { Link, useLocation } from "react-router-dom";

const Section = styled.section`
  position: relative;
  height: auto;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;

  background-image: url(images/Noisenoise.png);
  mix-blend-mode: soft-light;
  visibility: hidden;
`;

const Right = styled.div`
  position: absolute;
  left: 2%;

  background-color: ${(props) => props.theme.grey};
  /* width: 65%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    width: 40rem;
    margin-right: 6rem;
    color: black;
  }

  @media screen and (max-width: 600px) {
    top: 30vh;
  }
`;

const Item = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.loc === "/" ? "40rem" : "30%")};
  margin-right: ${(props) => (props.loc === "/" ? "6rem" : "0")};
  margin-bottom: ${(props) => (props.loc !== "/" ? "50px" : "0")};

  a {
    position: relative;
  }

  a::before {
    content: "";
    position: absolute;
    top: -200px;
    bottom: -200px;
    left: -100px;
    right: -100px;
  }

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
    position: relative;
  }

  h4 {
    display: inline-block;
    width: fit-content;
    font-weight: 500;
    text-align: justify;
    justify-content: center;
    position: absolute;
    visibility: hidden;
    color: #eadede;
    cursor: pointer;
  }

  &:hover {
    background-color: black;

    img {
      opacity: 0.6;
    }

    h4 {
      visibility: visible;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1000px) {
    width: ${(props) => (props.loc === "/" ? "40rem" : "50%")};
  }

  @media screen and (max-width: 600px) {
    width: 100vw;
    margin-right: ${(props) => (props.loc === "/" ? "3rem" : "0")};

    h4 {
      font-size: 1.2rem;
    }

    a::before {
      content: "";
      position: absolute;
      top: -100px;
      bottom: -100px;
      left: -100px;
      right: -100px;
    }
  }
`;

const PageStyleChange = gsap.to(".App", {
  duration: 1.0,
  backgroundColor: "#000",
  color: "#fff",
  overwrite: "auto",
});

export const Product = ({
  img,
  title = "",
  href = "/",
  className = "",
  location,
}) => {
  return (
    <Item className={className} loc={location}>
      <img src={img} alt={title} />
      <h4>
        <a href={href}>{title}</a>
      </h4>
    </Item>
  );
};

const WebDevelopment = () => {
  const currentLocation = useLocation();

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const horizontalRef = useRef(null);
  const overlayRef = useRef(null);

  useLayoutEffect(() => {
    let element = ref.current;
    let scrollingElement = horizontalRef.current;

    let pinWrapWidth = scrollingElement.offsetWidth;

    let tl = gsap.timeline();

    setTimeout(() => {
      tl.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: () => "+=" + scrollingElement.offsetWidth,
          scroller: ".App", // locomotive element
          scrub: 1,
          pin: true,
        },
        // we need to increase the scrolling height of this element same as scrolling width
        // height: `${scrollingElement.scrollWidth}px`,
        ease: "none",
      });

      // horizontal scrolling
      tl.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: () => "+=" + scrollingElement.offsetWidth,
          scroller: ".App", // locomotive element
          scrub: 1,
        },
        // we need to increase the scrolling height of this element same as scrolling width
        x: -pinWrapWidth,
        ease: "none",
      });

      tl.to(overlayRef.current, {
        scrollTrigger: {
          id: "overlay",
          trigger: scrollingElement,
          start: () => "+=" + scrollingElement.offsetWidth,
          scroller: ".App",
          scrub: true,
        },
        autoAlpha: 1,
      });
    }, 1000);
    ScrollTrigger.refresh();

    return () => {
      // Let's clear instances
      tl.kill();
      ScrollTrigger.getAll().forEach((instance) => {
        instance.kill();
      });
    };
  }, []);

  return (
    <div className="web-dev">
      <Section ref={ref} id="work">
        <Overlay ref={overlayRef} />
        <HorizontalText text={"Website \u00a0  •"} />
        <Right ref={horizontalRef}>
          <Product
            img={detex}
            title="Detex Similarity API"
            href="/personal-projects-detex-text-similarity-api"
            location={currentLocation.pathname}
          />
          <Product
            img={ftPWC}
            title="Financial Times × PwC"
            href="/work-ft-pwc"
            location={currentLocation.pathname}
          />
          <Product
            img={img1}
            title="Empire Clinic"
            href="/work-empire-clinic-website"
            location={currentLocation.pathname}
          />
          <Product
            img={img2}
            title="Displore Recommendation System"
            href="/work-displore-website"
            location={currentLocation.pathname}
          />
          <Product
            img={img3}
            title="Vibe Ecommerce"
            href="/work-vibe-ecommerce"
            location={currentLocation.pathname}
          />
          <Product
            img={coco}
            title="Coco Da Explorer"
            href="/personal-projects-coco-da-explore"
            location={currentLocation.pathname}
          />
          <Product
            img={vv}
            title="V&V Properties"
            href="/work-vv-properties"
            location={currentLocation.pathname}
          />
          <Product img={placeholder} location={currentLocation.pathname} />
        </Right>
        <HorizontalLeft text={"Development \u00a0  • \u00a0"} />
      </Section>
    </div>
  );
};

export default WebDevelopment;
